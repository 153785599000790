import store from 'store';
import { actions } from './actions';
import availableLanguages from '../../locale/available';

const parseLocale = () => {
  const navLocale = navigator.language.slice(0, 2);

  if (availableLanguages.includes(navLocale)) {
    return navLocale;
  }

  return 'en';
};

const initialState: CR.Settings = {
  isMobileView: 'false',
  locale: parseLocale(),
};

Object.entries(initialState).forEach(([key, value]) => {
  store.set(`app.settings.${key}`, value);
});

export default function reducer(state = initialState, action: CR.SettingsDispatchAction): CR.Settings {
  switch (action.type) {
    case actions.SET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
