import { actions } from './actions';

const initialState: CR.GraphHopper = {
  routes: [],
  error: {},
};

export default function reducer(state = initialState, action: CR.GraphHopperDispatchAction): CR.GraphHopper {
  switch (action.type) {
    case actions.SET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
