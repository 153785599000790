import {
  all, takeEvery, put, call, race, delay,
} from 'redux-saga/effects';
import { api } from '../../utils/net';
import { actionNetwork } from './actions';

function* REQUEST(args: CR.NetworkReduxAction) {
  const { payload: { options, action = 'missingAction', extra } } = args;

  try {
    yield put<CR.NetworkDispatchReduxAction>({
      type: `${action}/request`,
      payload: {
        options,
        extra,
      },
    });

      type Race = {
        response: Response,
        timeout: boolean
      };

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { response, timeout }: Race = yield race({
        response: call(api, options),
        timeout: delay(60 * 1000),
      });

      // handle timeouts

      if (timeout) {
        yield put<CR.NetworkDispatchReduxAction>({
          type: `${action}/error`,
          payload: {
            error: {
              message: 'Connection timeout!',
            },
          },
        });

        return;
      }

      // get json data
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const data: unknown = yield call([response, response.json]);

      if (response.ok) {
        yield put<CR.NetworkDispatchReduxAction>({
          type: `${action}/success`,
          payload: {
            data: { ...data as Record<string, unknown> },
          },
        });

        return;
      }

      // oops, something happened

      yield put<CR.NetworkDispatchReduxAction>({
        type: `${action}/error`,
        payload: {
          error: { ...data as Record<string, unknown> },
        },
      });
  } catch (error) {
    const e = error as Error;

    yield put({
      type: `${action}/error`,
      payload: {
        error: { ...e },
      },
    });
  }
}

export default function* rootSaga(): Generator {
  yield all([
    takeEvery(actionNetwork, REQUEST),
  ]);
}
