export const actionNetwork = 'network/request';

export const request = (
  options: CR.NetworkReduxAction['payload']['options'],
  action: CR.NetworkReduxAction['payload']['action'],
  extra?: CR.NetworkReduxAction['payload']['extra'],
): CR.NetworkReduxAction => ({
  type: actionNetwork,
  payload: {
    options,
    action,
    extra,
  },
});

export default request;

export function networkActions(reducer: string): Record<string, string> {
  return {
    LOAD: `${reducer}`,
    REQUEST: `${reducer}/request`,
    SUCCESS: `${reducer}/success`,
    ERROR: `${reducer}/error`,
    RESET: `${reducer}/reset`,
  };
}

function getPrefix(prefix: string, type?: string) {
  const prefixUp = prefix.toUpperCase();
  return type ? `${prefixUp}_${type}` : prefixUp;
}

export function extraNetworkActions(prefix: string, reducer: string): Record<string, string> {
  return {
    [getPrefix(prefix)]: `${reducer}`,
    [getPrefix(prefix, 'REQUEST')]: `${reducer}/request`,
    [getPrefix(prefix, 'SUCCESS')]: `${reducer}/success`,
    [getPrefix(prefix, 'ERROR')]: `${reducer}/error`,
    [getPrefix(prefix, 'RESET')]: `${reducer}/reset`,
  };
}
