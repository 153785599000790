import * as React from 'react';
import FirstSlide from './FirstSlide';
import BottomSlide from './BottomSlide';

const BottomSheetContent = (): JSX.Element => (
  <>
    <FirstSlide />

    <div className="separator" />

    <BottomSlide />
  </>
);

export default BottomSheetContent;
