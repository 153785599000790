import { networkActions } from '../network/actions';

const actions = {
  ...networkActions('order/details'),
};

export default actions;

export function load(payload: CR.OrderDispatchAction['payload']): CR.OrderDispatchAction {
  return {
    type: actions.LOAD,
    payload,
  };
}

export function reset(): CR.OrderDispatchAction {
  return {
    type: actions.RESET,
  };
}
