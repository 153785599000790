import { all, takeLatest, put } from 'redux-saga/effects';
import actions from './actions';
import { get } from '../../utils/net';
import { request } from '../network/actions';

// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
const url = `${process.env.REACT_APP_APP_CITYRIDE}${process.env.REACT_APP_API_VER}/orders`;

const orderDetails = (id: string) => get(`${url}/${id}`);

function* LOAD(payload: Required<CR.OrderDispatchAction>) {
  const { payload: { id } } = payload;
  yield put(request(orderDetails(id), actions.LOAD));
}

export default function* rootSaga(): Generator {
  yield all([
    takeLatest(actions.LOAD, LOAD),
  ]);
}
