import actions from './actions';

const initialState: CR.Order = {
  order: undefined,
  loading: false,
  error: {},
};

export default function reducer(state = initialState, action: CR.NetworkDispatchReduxAction): CR.Order {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.SUCCESS:
      return {
        ...state,
        order: { ...action.payload.data as CR.OrderCRUD },
        loading: false,
      };
    case actions.ERROR:
      return {
        ...initialState,
        error: { ...action.payload.error },
      };
    case actions.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
