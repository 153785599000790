import * as React from 'react';
import {
  Provider, useDispatch, useSelector,
} from 'react-redux';
import { isUndefined, isEmpty } from 'lodash';
import useInterval from './utils/useInterval';
import store from './redux/init';
import OrderMap from './components/OrderMap';
import BottomSheet from './components/BottomSheet';
import { load as loadOrder } from './redux/order/actions';

const ShowSpinner = () => (
  <div className="loading">
    <img
      alt="logo-itaxi"
      src="/assets/logo-itaxi-white.svg"
    />
    <div className="loader">Loading...</div>
  </div>
);

const NoOrder = () => (
  <div className="noOrder">
    <div className="info">
      No order details
    </div>
    <a href="https://itaxi.md/app/" target="_blank" rel="noreferrer" className="actionButton">
      Install itaxi app
    </a>
  </div>
);

function App(): JSX.Element {
  const dispatch = useDispatch();
  const idHash = window.location.pathname.replace('/', '');

  const order = useSelector((state: CR.RootState) => state.order);

  React.useEffect(() => {
    if (idHash) {
      dispatch(loadOrder({ id: idHash }));
    }
  }, [dispatch, idHash]);

  useInterval(
    () => {
      dispatch(loadOrder({ id: idHash }));
    }, idHash && isEmpty(order.error) ? parseInt(process.env.REACT_APP_GETORDER as string, 10) : null,
  );

  return (
    <>
      {order.loading && isUndefined(order.order)
        ? <ShowSpinner />
        : (
          <>
            {!isUndefined(order.order)
              ? (
                <>
                  <OrderMap />
                  <BottomSheet />
                </>
              )
              : <NoOrder />}
          </>
        )}
    </>
  );
}

const WrappedApp = (): JSX.Element => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default WrappedApp;
