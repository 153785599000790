import * as React from 'react';
import { useSelector } from 'react-redux';

const FirstSlide = (): JSX.Element => {
  const { order: orderState } = useSelector((state: CR.RootState) => state.order);
  const order = orderState as CR.OrderCRUD;

  const { routes } = useSelector((state: CR.RootState) => state.graphHopper);

  let timeleft = '';

  if (routes[0] === undefined) {
    if (order.route_points.length === 1) {
      let endTime = Date.now();

      if (order.route_points[0].ended_at) {
        endTime = Date.parse(order.route_points[0].ended_at);
      }

      timeleft = `${Math.floor((endTime - Date.parse(order.route_points[0].started_at)) / 60000)} min`;
    } else {
      // for 2 points
    }
  } else {
    timeleft = `${Math.floor(routes[0].summary?.totalTime as number / 60000)} min`;
  }

  return (
    <div className="firstSlide">
      <div className="top">
        <div className="dragBar" />

        <div>
          <div className="orderStatus">
            {order.status?.name}
          </div>

          <div className="timeLeft">
            {order.status?.id === 80 ? null : timeleft}
          </div>
        </div>

      </div>

      <div className="bottom">
        <div className="left">
          <div className="carNumber">
            {order.car?.registration_nb}
          </div>
          <div className="carModel">
            {order.car && `${order.car.brand.name} ${order.car.model.name} ${order.car.color.name}`}
          </div>
          <div className="driverName">
            {order.driver && order.driver.first_name}
          </div>
        </div>

        <div className="right">
          <div className="car">
            <img
              className="image"
              src={order.car?.photo || 'invalid_link'}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.className += ' hideImageOnError';
              }}
            />
          </div>

          <div className="driver">
            <img
              className="image"
              src={order.driver?.profile_picture || 'invalid_link'}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = '/assets/user.svg';
                e.currentTarget.className += ' border-circle';
              }}
            />

            <div className="rating">
              <img
                alt="star"
                src="/assets/star_icon.png"
              />
              <div className="score">
                {Math.round(order.driver.rating * 100) / 100}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSlide;
