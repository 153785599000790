import { createLayerComponent } from '@react-leaflet/core';
import LeafletDriftMarker from 'leaflet-drift-marker';
import 'leaflet-rotatedmarker';
import { isEqual } from 'lodash';
import distance from '@turf/distance';
import { point } from '@turf/helpers';

const getCarAngle = (source, destination) => {
  // Converts from degrees to radians.
  const toRadians = (degrees) => {
    return (degrees * Math.PI) / 180;
  }

  // Converts from radians to degrees.
  const toDegrees = (radians) => {
    return (radians * 180) / Math.PI;
  }

  let startLat = toRadians(source[0]);
  let startLng = toRadians(source[1]);
  let destLat = toRadians(destination[0]);
  let destLng = toRadians(destination[1]);

  const y = Math.sin(destLng - startLng) * Math.cos(destLat);
  const x = Math.cos(startLat) * Math.sin(destLat)
    - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
  let brng = Math.atan2(y, x);
  brng = toDegrees(brng);

  return (brng + 360) % 360;

  // const lngDifference = (destination[1] - source[1]);

  // const y = Math.sin(lngDifference) * Math.cos(destination[0]);
  // const x = Math.cos(source[0]) * Math.sin(destination[0]) - Math.sin(source[0]) * Math.cos(destination[0]) * Math.cos(lngDifference);

  // let bearing = Math.atan2(y, x);
  // bearing *= (180 / Math.PI);
  // bearing = (bearing + 360) % 360;

  // return bearing;
};

export default createLayerComponent(
  function createMarker({ position, ...options }, ctx) {
    const instance = new LeafletDriftMarker(position, options);
    return { instance, context: { ...ctx, overlayContainer: instance } };
  },
  function updateMarker(marker, props, prevProps) {
    // Enhanced marker with Rotate Marker
    if (props.rotationAngle !== prevProps.rotationAngle) {
      marker.setRotationAngle(props.rotationAngle);
    }

    if (props.rotationOrigin !== prevProps.rotationOrigin) {
      marker.setRotationOrigin(props.rotationOrigin);
    }

    // Original code below
    if (!isEqual(prevProps.position, props.position) && typeof props.duration === 'number') {
      marker.slideTo(props.position, {
        duration: props.duration,
        keepAtCenter: props.keepAtCenter,
      });

      // Trigger angle setting
      const fromLat = prevProps.position[0];
      const fromLng = prevProps.position[1];
      
      const toLat = props.position[0];
      const toLng = props.position[1];
      

      const from = point([fromLng, fromLat]);
      const to = point([toLng, toLat]);
      const dist = distance(from, to);

      if (dist * 1000 > 5) {
        const angle = getCarAngle(prevProps.position, props.position)
        marker.setRotationAngle(angle);
      }
    }

    if (props.icon != null && props.icon !== prevProps.icon) {
      marker.setIcon(props.icon);
    }

    if (props.zIndexOffset != null && props.zIndexOffset !== prevProps.zIndexOffset) {
      marker.setZIndexOffset(props.zIndexOffset);
    }

    if (props.opacity != null && props.opacity !== prevProps.opacity) {
      marker.setOpacity(props.opacity);
    }

    if (marker.dragging != null && props.draggable !== prevProps.draggable) {
      if (props.draggable === true) {
        marker.dragging.enable();
      } else {
        marker.dragging.disable();
      }
    }
  },
);
