export const actions = {
  SET: 'graphHopper/set',
};

export function setGraphHopper(payload: CR.GraphHopperDispatchAction['payload']): CR.GraphHopperDispatchAction {
  return {
    type: actions.SET,
    payload,
  };
}
