import * as React from 'react';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { isUndefined } from 'lodash';

type Props = {
  waypoints: CR.ParseWaypoints,
};

const pickupMarker = L.icon({
  iconUrl: '/assets/pin_start.svg',
  iconAnchor: [15, 37],
});

const destinationMarker = L.icon({
  iconUrl: '/assets/pin_stop.svg',
  iconAnchor: [15, 37],
});

const Markers = ({ waypoints }: Props): JSX.Element => {
  const generateStopMarkers = () => {
    if (isUndefined(waypoints.stops)) {
      return null;
    }

    return waypoints.stops.map((el) => (
      <Marker key={`stop ${el.alias}`} position={L.latLng(el.latitude, el.longitude)}>
        <Popup>
          {el.alias}
        </Popup>
      </Marker>
    ));
  };

  return (
    <>
      <Marker key="pickup" position={L.latLng(waypoints.pickup.latitude, waypoints.pickup.longitude)} icon={pickupMarker}>
        <Popup>
          {waypoints.pickup.alias}
        </Popup>
      </Marker>

      {generateStopMarkers()}

      {!isUndefined(waypoints.destination) && (
        <Marker key="destination" position={L.latLng(waypoints.destination.latitude, waypoints.destination.longitude)} icon={destinationMarker}>
          <Popup>
            {waypoints.destination.alias}
          </Popup>
        </Marker>
      )}
    </>
  );
};

export default Markers;
