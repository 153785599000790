import {
  all, take, put, takeLatest,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import store from 'store';
import { setSetting, actions } from './actions';

function* isMobileView(load = false) {
  const currentState = window.innerWidth < 768;
  const currentStateString = currentState ? 'true' : 'false';

  const prevState = store.get('app.settings.isMobileView') as string;

  if (currentStateString !== prevState || load) {
    const data: CR.SettingsDispatchAction['payload'] = {
      isMobileView: currentStateString,
    };

    yield put(setSetting(data));
  }
}

function ToLocalStorage(args: CR.SettingsDispatchAction) {
  const { payload } = args;

  Object.entries(payload).forEach(([key, value]) => {
    store.set(`app.settings.${key}`, value);
  });
}

function* SETUP() {
  const channel = eventChannel((emitter) => {
    const onResize = (e: unknown) => {
      // send the event to our saga
      emitter(e);
    };

    window.addEventListener('resize', onResize);

    // return an unsubscribe method
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  // detect isMobileView setting on app load and window resize
  yield isMobileView(true);

  // process events until operation completes
  while (true) {
    yield take(channel);
    yield isMobileView();
  }
}

export default function* rootSaga(): Generator {
  yield all([
    takeLatest(actions.SET, ToLocalStorage),
    SETUP(), // run once on app load to init listeners
  ]);
}
