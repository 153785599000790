import network from './network/sagas';

import settings from './settings/reducers';
import settingsSagas from './settings/sagas';

import order from './order/reducers';
import orderSagas from './order/sagas';

import graphHopper from './graphHopper/reducers';

const lists = {
  reducers: {
    settings,
    order,
    graphHopper,
  },
  sagas: [
    network(),
    settingsSagas(),
    orderSagas(),
  ],
};

export default lists;
