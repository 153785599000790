export const actions = {
  SET: 'settings/set',
};

export function setSetting(payload: CR.SettingsDispatchAction['payload']): CR.SettingsDispatchAction {
  return {
    type: actions.SET,
    payload,
  };
}
