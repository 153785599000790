import * as React from 'react';
import L from 'leaflet';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import CristiMarker from '../../utils/CristiMarker';

const carMarker = L.icon({
  iconUrl: '/assets/carMarker.svg',
  iconAnchor: [9, 14],
});

const DriverMarker = (): JSX.Element | null => {
  const order = useSelector((state: CR.RootState) => state.order);
  const [render, setRender] = React.useState(false);

  React.useEffect(() => {
    const orderForRender = ![80, 90].includes(order.order?.status.id as number);

    if (orderForRender !== render) {
      setRender(orderForRender);
    }
  }, [order.order?.status, render]);

  const getDriverCurrentPosition = () => {
    if (order.order?.driver_location && !isEmpty(order.order.driver_location)) {
      return [
        order.order.driver_location.latitude,
        order.order.driver_location.longitude,
      ];
    }

    if (order.order?.tracking && !isEmpty(order.order.tracking)) {
      return [
        order.order.tracking[order.order.tracking.length - 1].latitude,
        order.order.tracking[order.order.tracking.length - 1].longitude,
      ];
    }

    return [];
  };

  return !render ? null : (
    <CristiMarker
      key={order.order?.driver.id}
      // @ts-ignore
      icon={carMarker}
      // DriftMarker addon below
      position={getDriverCurrentPosition()}
      // time in ms that marker will take to reach its destination
      duration={2000}
      // RotatedMarker addon below
      // Rotation is done internally in the marker based on positions
      // rotationOrigin="center center"
    />
  );
};

export default DriverMarker;
